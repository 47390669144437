import { NgModule } from "@angular/core";
import { AmountFormtedPipe } from "./pipes/amount-formated.pipe"; 

@NgModule({
  imports: [
  ],
  declarations: [AmountFormtedPipe],
  providers: [
  ], 
  exports: [
    AmountFormtedPipe,
  ]
})
export class SharedModule { }
